import React from "react"
import { Col, Row } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import { PageLayout, PageTitle } from "../components"
import { SEO } from "../utils"

export default ({ data }) => {
  return (
    <PageLayout pageName="contact">
      <SEO title="Contacts" />
      <PageTitle title="Contacts" />
      <Container className="text-left">
        <Row>
          <Col md="8 offset-md-2">
            <p style={{ fontSize: "20px" }}>
              Je suis généralement assez facile à joindre, vous pouvez utiliser
              les differents canaux suivants:
            </p>

            <div>
              <h4 style={{ fontSize: "24px", fontWeight: "bold" }}>
                Email:&nbsp;{" "}
                <a href="mailto:moafembe@gmail.com">moafembe@gmail.com</a>
              </h4>
              <p>
                J'utilise beaucoup ma boite mail. Laissez moi un message, Je
                vous répondrai rapidement
              </p>
            </div>
            <hr />

            <div>
              <h4 style={{ fontSize: "24px", fontWeight: "bold" }}>
                Youtube:&nbsp;{" "}
                <a target='_blank' href="https://www.youtube.com/@regisatemengue">Régis ATEMENGUE</a>
              </h4>
              <p>
                J'ai démarré une chaine Youtube pour partager mes connaissances et mon savoir faire. Merci de rejoindre ma communauté:
              </p>
            </div>

            <hr />

            <div>
              <h4 style={{ fontSize: "24px", fontWeight: "bold" }}>
                Twitter: &nbsp;
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://twitter.com/regis_ate"
                >
                  @regis_ate
                </a>
              </h4>
              <p>
                Mon fil d'actualite Twitter est basé sur la TECH. Je lis mes DM
                mais je préférerais les e-mails si vous me contactez à
                l'improviste à propos de quelque chose.
              </p>
            </div>

            <hr />

            <div>
              <h4 style={{ fontSize: "24px", fontWeight: "bold" }}>
                Linkedin: &nbsp;
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/in/regis-atemengue"
                >
                  @regis_ate
                </a>
              </h4>
              <p>
                Mon parcours est assez bien couvert sur les 3 dernières années.
                J'accepte les relations des futurs clients et/ou partenaires.
              </p>
            </div>

            <hr />
            <div>
              <h4 style={{ fontSize: "24px", fontWeight: "bold" }}>
                Instagram: &nbsp;
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/regis_ate/"
                >
                  @regis_ate
                </a>
              </h4>
              <p>
                Sur Instragram vous retrouvez quelques photos, code et mon
                environnement de travail. J'utilise de moins en moins le réseau
                mais j'ai prevu de recommencer à poster bientôt .
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </PageLayout>
  )
}
